import { useStaticQuery, graphql } from "gatsby"

export const AllPosts = () => {
  const { allContentfulPost } = useStaticQuery(
    graphql`
      query {
        allContentfulPost(sort: { order: DESC, fields: createdAt }) {
          edges {
            node {
              tItle
              author {
                name
              }
              featured
              slug
              summary
              category
              createdAt(formatString: "MM/DD/YY")
              featuredImage {
                fluid(maxWidth: 2000) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  )
  return allContentfulPost
}
