import React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import Content from "../components/Sections/Article/Content"
import FeaturedImage from "../components/Sections/Article/FeaturedImage"
import Text from "../components/Sections/Article/Text"
import Author from "../components/Sections/Article/Author"
import Arrows from "../components/Sections/Article/ArticleArrows"
import Aside from "../components/Sections/Article/Aside"
import Subscription from "../components/Sections/Subscription"
import Feed from "../components/Sections/SocialFeed"
import RecentPost from "../components/Sections/Article/RecentPost"
import Notification from "../components/Sections/Article/Notification"

const article = ({ data }) => {
  const posts = data.allContentfulPost.edges
  return (
    <Layout>
      <SEO
        title={data.contentfulPost.tItle}
        description={data.contentfulPost.summary}
      />
      <Content
        left={
          <React.Fragment>
            <FeaturedImage fluid={data.contentfulPost.featuredImage.fluid} />
            <Text data={data.contentfulPost} />
            <Author author={data.contentfulPost.author} />
          </React.Fragment>
        }
        right={
          <React.Fragment>
            <Aside>
              {posts.map((post, i) => (
                <RecentPost key={i} data={post} />
              ))}
            </Aside>
            <Notification />
          </React.Fragment>
        }
      />
      <Subscription />
      <Feed />
    </Layout>
  )
}

export default article

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      tItle
      createdAt(formatString: "MM/DD/YY")
      category
      summary
      author {
        name
        bio
        portrait {
          fluid(maxWidth: 400) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      featuredImage {
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
      body {
        raw
      }
    }
    allContentfulPost(
      filter: { slug: { ne: $slug } }
      sort: { fields: createdAt, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          tItle
          slug
          featuredImage {
            fluid(maxWidth: 340) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`
